.trip {
    margin: 4rem 6rem;
    color: #2a2a2a;
  }
  
  .trip h1 {
    font-size: 4rem;
  }

  .trip p {
    font-size: 1.5rem;
    
  }
  
  .tripCard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
  }
  
  .t-card {
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
  }

  .t-card p {
    font-size: 1.2rem;
    
  }
  
  .t-image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
  }
  
  .t-image img {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
    object-fit:contain
  }
  
  .t-image:hover img {
    transform: scale(1.3);
  }
  
  .t-card h4 {
    font-size: 1.8rem;
    padding: 0.9rem 0 0.2rem 0;
  }
  
  @media screen and (max-width: 850px) {
    .trip {
      margin: 4rem 2rem;
    }
    .tripCard {
      flex-direction: column;
    }
    .t-card {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  