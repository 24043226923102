.card {
  display: flex;
  align-items: center;
  width: calc(100% - 20px); /* Subtract 20px for margin */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.card-image {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
}

.card-content {
  flex: 1;
  text-align: left;
}

.card-title {
  margin-top: 0;
  font-size: 20px;
}

.card-description {
  margin-top: 10px;
  font-size: 14px;
}

.card + .card {
  margin-top: 20px; /* Add margin between cards */
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }
  
  .card-image {
    margin-right: 0;
    margin-bottom: 20px;
  }
}


a {
  color: #000;
  text-decoration: none;
}
