form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  textarea {
    height: 150px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
    display: inline;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    form {
      padding: 10px;
    }
  
    h2 {
      font-size: 24px;
    }
  
    input,
    textarea {
      padding: 8px;
    }
  }
  